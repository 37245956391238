import "../scss/main.scss";
import "../images/how-1.webp";
import "../images/how-2.webp";
import "../images/how-3.webp";
import "../images/recipes-1.png";
import "../images/recipes-2.png";
import "../images/recipes-3.png";
import "../images/hero.webp";
import "../images/cta-bg.svg";
import "../images/cta-hero-bg.svg";
import "../images/cta-hero-bg-2.svg";
import "../images/excl.svg";
import "../images/bg-600.webp";
import "../images/available.svg";
import "../images/checkbox.svg";
import "../images/limited.svg";
import "../images/features-1.webp";
import "../images/features-2.webp";
import "../images/features-3.webp";
import "../images/features-4.webp";
import "../images/good-1.svg";
import "../images/good-2.svg";
import "../images/good-3.svg";
import "../images/good-4.svg";


(function () {
    document.addEventListener('DOMContentLoaded', function(event) {
        const header = document.querySelector('.header');

        const stickyHeaderOnScroll = () => {
            if (window.scrollY > 80) {
                header.classList.add("header--sticky");
            }

            if (window.scrollY <= 0) {
                header.classList.remove("header--sticky");
            }
        }

        window.onscroll = stickyHeaderOnScroll;

        window.ctaName = '';

        window.signupEventSent = false;
        window.subcribeEventSent = false;

        document.querySelectorAll('.cta').forEach((cta) => {
            cta.addEventListener('click', () => {
                document.querySelector('#banner').classList.add('hidden');
                document.querySelector('#form').classList.remove('hidden');
                window.scrollTo(0, document.body.scrollHeight);

                window.lastCTAClickedName = cta.className.split(" ")[0];

                if(!window.signupEventSent) {
                    gtag('event', 'signup', {
                        'cta': window.lastCTAClickedName
                    });

                    window.signupEventSent = true;
                }
            });
        });

        document.querySelector('.signup__button').addEventListener('click', () => {

            if(!window.subcribeEventSent) {
                gtag('event', 'subscribe', {
                    'cta': window.lastCTAClickedName
                });

                window.subcribeEventSent = true;
            }
        });

        document.body.classList.add('loaded');
    });
}
)();